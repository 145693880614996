/* eslint-disable arrow-parens */

export default (errorObj, map) => {
  const text = []

  const errorMap = []
  let errorDefault = {}

  map.forEach((item) => {
    if (item.error !== '') {
      errorMap.push(item)
    } else {
      errorDefault = item
    }
  })

  if (Array.isArray(errorObj)) {
    errorMap.forEach((err) => {
      if (errorObj.some((str) => str.includes(err.error)) && text.length === 0) {
        text.push(err.message)
      }
    })
  } else if (typeof errorObj === 'object') {
    Object.keys(errorObj).forEach((key) => {
      errorMap.forEach((err) => {
        if (Array.isArray(errorObj[key])) {
          if (errorObj[key].some((str) => str.includes(err.error))) {
            text.push(err.message)
          }
        } else if (errorObj[key].includes(err.error)) {
          text.push(err.message)
        }
      })
    })
  } else if (typeof errorObj === 'string') {
    errorMap.forEach((err) => {
      if (errorObj.includes(err.error)) {
        text.push(err.message)
      }
    })
  }

  if (text.length === 0) {
    text.push(errorDefault)
  }

  let textResponse = ''

  text.forEach((err) => {
    textResponse = `${textResponse} ${err}`
  })

  return textResponse
}

/* eslint-enable arrow-parens */
